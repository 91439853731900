import { initializeApp } from "firebase/app";
import {
  getAuth,
  User,
  UserCredential,
  NextOrObserver,
  Unsubscribe as AuthUnsubscribe,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  Unsubscribe,
  sendPasswordResetEmail,
  signInWithCustomToken,
} from "firebase/auth";
import {
  connectFirestoreEmulator,
  collection,
  doc,
  DocumentReference,
  getDoc,
  getFirestore,
  setDoc,
  SetOptions,
  DocumentSnapshot,
  DocumentData,
  onSnapshot,
  where,
  query,
  CollectionReference,
  orderBy,
  getDocs,
} from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

import {
  ExtendedEmailStatuses,
  Organization,
  OrganizationStats,
  AccountSettings,
  OutreachEmailStatuses,
  DbOrganizationSettings,
  OrganizationSettings,
  OrgWebhookStatus,
} from "../types/organizations";
import { AppUser, UID, OrgDisplayUser, OutreachUser } from "../types/users";
import { getStatsDateRange } from "../common/utils/date-time";
import { removeUndefineds } from "../common/utils/data";
import { firebaseConfig, isDevelopment, isProduction } from "./config";

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseFirestore = getFirestore(firebaseApp);
export const firebaseFunctions = getFunctions(firebaseApp);
firebaseFunctions.region = isProduction ? "us-central1" : "europe-west3";

type StatsResult = {
  [key: string]: Partial<OrganizationStats>;
};

type Activity = {
  status: OutreachEmailStatuses;
  prospectId: number;
  email?: string;
  didGuess?: boolean;
  optedOut?: boolean;
};

// --------------------
// Emulators
// --------------------

if (isDevelopment) {
  console.warn("Running in development environment");
  connectAuthEmulator(firebaseAuth, "http://localhost:9099");
  connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
  connectFirestoreEmulator(firebaseFirestore, "localhost", 5002);
}

// --------------------
// Utils
// --------------------

const setDocSafely = <T>(
  docRef: DocumentReference<T>,
  data: T,
  options?: SetOptions
) => {
  const sanitized = structuredClone(data);
  removeUndefineds(sanitized);
  return setDoc(docRef, sanitized, options || {});
};

// --------------------
// Data converters
// --------------------

export const convertDbAccountToAppAccount = (
  dbUser: AppUser | null,
  organization?: Organization
): AppUser | null => {
  const {
    uid,
    email: authEmail,
    emailVerified: authEmailVerified,
  } = firebaseAuth.currentUser || {};

  if (!uid || !authEmail || !dbUser) {
    return null;
  }

  const {
    id: dbOrganizationId,
    name: dbOrganizationName,
    prospectsWebhookId: dbProspectsWebhookId,
    ...rest
  } = organization || {};

  const appUser: AppUser = {
    ...rest,
    ...dbUser,
    uid,
    email: authEmail,
    emailVerified: authEmailVerified,
    organizationId: dbOrganizationId,
    organizationName: dbOrganizationName,
    isProspectWebhookCreated: !!dbProspectsWebhookId,
  };

  return appUser;
};

export function convertAppOrganizationSettingsToDbOrganizationSettings(
  organizationSettings: OrganizationSettings
): DbOrganizationSettings {
  const { isEmailStatusCustomFieldEnabled, emailStatusCustomField, ...rest } =
    organizationSettings;
  const dbOrganizationSettings: DbOrganizationSettings = {
    isCustomFieldEnabled:
      organizationSettings.isEmailStatusCustomFieldEnabled || false,
    customField: organizationSettings.emailStatusCustomField || null,
    ...rest,
  };
  return dbOrganizationSettings;
}

export function convertDbOrganizationSettingsToAppOrganizationSettings(
  organizationSettings: DbOrganizationSettings
): OrganizationSettings {
  const { customField, isCustomFieldEnabled, ...rest } = organizationSettings;
  const appOrganizationSettings: OrganizationSettings = {
    isEmailStatusCustomFieldEnabled:
      organizationSettings.isCustomFieldEnabled || false,
    emailStatusCustomField: organizationSettings.customField || null,
    ...rest,
  };
  return appOrganizationSettings;
}

// --------------------
// Authentication
// --------------------

export const onAuthStateChangedHook = (
  observer: NextOrObserver<User>
): AuthUnsubscribe => onAuthStateChanged(firebaseAuth, observer);

export const signUserUp = (
  email: string,
  password: string
): Promise<UserCredential> =>
  createUserWithEmailAndPassword(firebaseAuth, email, password);

export const signUserIn = (
  email: string,
  password: string
): Promise<UserCredential> =>
  signInWithEmailAndPassword(firebaseAuth, email, password);

export const signUserInWithToken = async (
  token: string
): Promise<UserCredential> => {
  return signInWithCustomToken(firebaseAuth, token);
};

export const resetPassword = (email: string): Promise<void> =>
  sendPasswordResetEmail(firebaseAuth, email);

export const signUserOut = async (): Promise<void> => {
  await signOut(firebaseAuth);
};

export const reload = () => {
  window.location.reload();
};

// --------------------
// Functions
// --------------------

const getOutreachAuthUrlFunction = httpsCallable<
  never,
  { result?: { outreachAuthUrl: string }; status: number }
>(firebaseFunctions, "getOutreachAuthUrl");
export const getOutreachAuthUrl = async (): Promise<{
  result?: { outreachAuthUrl: string };
  status: number;
}> => {
  const { data } = await getOutreachAuthUrlFunction();
  if (!data) {
    throw new Error("Error getting Outreach connect url");
  }
  return data;
};

const generateCustomAuthTokenFunction = httpsCallable<
  never,
  {
    result?: { token: string };
  }
>(firebaseFunctions, "generateCustomAuthToken");
export const generateCustomAuthToken = async (): Promise<string> => {
  const { data } = await generateCustomAuthTokenFunction();
  if (!data || !data.result?.token) {
    throw new Error("Error generating custom auth token");
  }
  return data.result.token;
};

const getMyOrganizationFunction = httpsCallable<
  never,
  { result?: DbOrganizationSettings; status: number }
>(firebaseFunctions, "getMyOrganization");
export const getMyOrganization = async (): Promise<{
  result?: DbOrganizationSettings;
  status: number;
}> => {
  const { data } = await getMyOrganizationFunction();
  if (!data) {
    throw new Error("Error getting organization");
  }
  return data;
};

const getOrgUsersFunction = httpsCallable<
  never,
  { result?: { users: OrgDisplayUser[] }; status: number }
>(firebaseFunctions, "getOrgUsers");
export const getOrgUsers = async (): Promise<{
  result?: { users: OrgDisplayUser[] };
  status: number;
}> => {
  const { data } = await getOrgUsersFunction();
  if (!data) {
    throw new Error("Error getting organization users");
  }
  return data;
};

const inviteOrgUserFunction = httpsCallable<
  { email: string },
  { status: number }
>(firebaseFunctions, "inviteOrgUser");
export const inviteOrgUser = async (
  email: string
): Promise<{
  status: number;
}> => {
  const { data } = await inviteOrgUserFunction({ email });
  return data;
};

const updateOrgSettingsFunction = httpsCallable<
  AccountSettings,
  { result?: Organization; status: number }
>(firebaseFunctions, "updateOrgSettings");
export const updateOrganizationSettings = async (
  userSettings: AccountSettings
): Promise<{
  result?: Organization;
  status: number;
}> => {
  const { data } = await updateOrgSettingsFunction(userSettings);
  if (!data) {
    throw new Error("Error updating organization");
  }
  return data;
};

const getOrgWebhookStatusFunction = httpsCallable<
  never,
  { result?: OrgWebhookStatus; status: number }
>(firebaseFunctions, "getOrgWebhookStatus");
export const getOrgWebhookStatus = async (): Promise<{
  result?: OrgWebhookStatus;
  status: number;
}> => {
  const { data } = await getOrgWebhookStatusFunction();
  if (!data) {
    throw new Error("Error getting organization webhook status");
  }
  return data;
};

const updateOrgWebhookStatusFunction = httpsCallable<
  OrgWebhookStatus,
  { result?: OrgWebhookStatus; status: number }
>(firebaseFunctions, "updateOrgWebhookStatus");
export const updateOrgWebhookStatus = async (
  WebhookStatus: OrgWebhookStatus
): Promise<{
  result?: OrgWebhookStatus;
  status: number;
}> => {
  const { data } = await updateOrgWebhookStatusFunction(WebhookStatus);
  if (!data) {
    throw new Error("Error getting organization webhook status");
  }
  return data;
};

const validateOutreachAuthCodeFunction = httpsCallable<
  string,
  { result?: Organization; status: number }
>(firebaseFunctions, "validateOutreachAuthCode");
export const validateOutreachAuthCode = async (
  outreachAuthCode: string
): Promise<{
  result?: Organization;
  status: number;
}> => {
  const { data } = await validateOutreachAuthCodeFunction(outreachAuthCode);
  if (!data) {
    throw new Error("Error validateOutreachAuthCode");
  }
  return data;
};

const setupProspectWebhookFunction = httpsCallable<
  never,
  { result?: { webhookId: string }; status: number }
>(firebaseFunctions, "setupProspectWebhook");
export const setupProspectWebhook = async (): Promise<{
  result?: { webhookId: string };
  status: number;
}> => {
  const { data } = await setupProspectWebhookFunction();
  if (!data) {
    throw new Error("Error setting up prospect webhook");
  }
  return data;
};

const getOutreachUsersFunction = httpsCallable<
  never,
  { result?: { users: OutreachUser[] }; status: number }
>(firebaseFunctions, "getOutreachUsers");
export const getOutreachUsers = async (): Promise<{
  result?: { users: OutreachUser[] };
  status: number;
}> => {
  const { data } = await getOutreachUsersFunction();
  if (!data) {
    throw new Error("Error getting Outreach users");
  }
  return data;
};

// --------------------
// Firestore
// --------------------

const usersCollection = collection(firebaseFirestore, "users");
const statsCollection = collection(firebaseFirestore, "stats");

export const getDbUser = async (uid: UID): Promise<AppUser | null> => {
  const dbUser = (await getDoc(doc(usersCollection, uid))).data();
  return (dbUser as AppUser) || null;
};

export const upsertAppUser = async (user: AppUser): Promise<AppUser> => {
  await setDocSafely(doc(usersCollection, user.uid), user);
  return user;
};

export const updateAppUser = async (
  user: Partial<AppUser>
): Promise<Partial<AppUser>> => {
  await setDocSafely(doc(usersCollection, user.uid), user, { merge: true });
  return user;
};

const getUniqueProspectCountsForDateRange = async ({
  allActivity,
  startDate,
  endDate,
}: {
  allActivity: CollectionReference<DocumentData>;
  startDate: Date;
  endDate?: Date;
}) => {
  const q = query(
    allActivity,
    where("time", ">", startDate),
    where("time", "<", endDate ?? new Date()),
    orderBy("time", "desc")
  );
  const docs = await getDocs(q);

  const lastActivityByProspectId: Record<number, Activity> = {};
  docs.forEach((doc) => {
    const data = doc.data() as Activity;
    const { prospectId } = data;
    if (prospectId) {
      lastActivityByProspectId[prospectId] = data;
    }
  });

  const bounceActivities: Activity[] = [];
  const enrichedActivities: Activity[] = [];
  docs.forEach((doc) => {
    const data = doc.data() as Activity;
    if (data.didGuess) {
      enrichedActivities.push(data);
    } else if (data.optedOut) {
      bounceActivities.push(data);
    }
  });

  const countByStatus = Object.values(lastActivityByProspectId).reduce<{
    [key in OutreachEmailStatuses | ExtendedEmailStatuses]: number;
  }>(
    (acc, { status }) => {
      acc[status] = (acc[status] || 0) + 1;

      return acc;
    },
    {} as { [key in OutreachEmailStatuses | ExtendedEmailStatuses]: number }
  );

  countByStatus[ExtendedEmailStatuses.EnrichedByBoring] =
    enrichedActivities.length;
  countByStatus[ExtendedEmailStatuses.BouncesPrevented] =
    bounceActivities.length;
  return countByStatus;
};

export const getRangeStats = async ({
  organizationId,
  startDate: startDateArg,
  endDate: endDateArg,
}: {
  organizationId: string;
  startDate: Date;
  endDate: Date;
}): Promise<StatsResult> => {
  const statsRef: DocumentReference<DocumentData> = doc(
    statsCollection,
    organizationId
  );

  // const graphDataCache = collection(statsRef, "graphData");
  const allActivity = collection(statsRef, "activity");
  const dateRange = getStatsDateRange(startDateArg, endDateArg);

  const result: StatsResult = {};
  let promises: Promise<void>[] = [];

  const getStatsForDateRange = async (
    startDate: Date,
    endDate: Date,
    customKey?: string
  ) => {
    const key = customKey || endDate.toDateString();
    result[key] = {
      [OutreachEmailStatuses.Valid]: 0,
      [OutreachEmailStatuses.Invalid]: 0,
      [OutreachEmailStatuses.Questionable]: 0,
      [OutreachEmailStatuses.NoEmail]: 0,
      [ExtendedEmailStatuses.EnrichedByBoring]: 0,
      [ExtendedEmailStatuses.BouncesPrevented]: 0,
    };

    const data = await getUniqueProspectCountsForDateRange({
      allActivity,
      startDate,
      endDate,
    });

    result[key] = {
      ...result[key],
      ...data,
    };

    result[key].prospectsChecked = Object.entries(OutreachEmailStatuses).reduce(
      (acc, curr) => {
        const [, value] = curr;
        return acc + (result[key][value] || 0);
      },
      0
    );
  };

  promises.push(getStatsForDateRange(startDateArg, endDateArg, `totals`));

  for (const endDate of dateRange) {
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 1);
    promises.push(getStatsForDateRange(startDate, endDate));

    // Calculate stats in batches of 10
    if (promises.length % 10 === 0) {
      await Promise.all(promises);
      promises = [];
    }
  }

  await Promise.all(promises); // Calculate remaining stats if any

  return result;
};

export const subToRunStats = (
  { organizationId }: { organizationId: string },
  observer: (snapshot: DocumentSnapshot<DocumentData>) => void
): Unsubscribe => {
  const docRef: DocumentReference<DocumentData> = doc(
    statsCollection,
    organizationId
  );

  return onSnapshot<DocumentData>(
    docRef,
    { includeMetadataChanges: false },
    observer
  );
};

export function subToEmailsInQueue(
  organizationId: string,
  callback: (emailsInQueue: number) => void
) {
  try {
    const docRef = doc(firebaseFirestore, "stats", organizationId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      const emailsInQueue = doc.data()?.emailsInQueue || 0;
      callback(emailsInQueue);
    });
    return unsubscribe;
  } catch (error) {
    console.error("Error listening to remaining emails", error);
  }
}

export const getRangeStatsFromCache = async ({
  organizationId,
  startDate: startDateArg,
  endDate: endDateArg,
}: {
  organizationId: string;
  startDate: Date;
  endDate: Date;
}): Promise<StatsResult> => {
  const statsRef: DocumentReference<DocumentData> = doc(
    statsCollection,
    organizationId
  );

  const graphDataCache = collection(statsRef, "chartDataCache");
  const dateRange = getStatsDateRange(startDateArg, endDateArg);

  const result: StatsResult = {};

  for (const date of dateRange) {
    const key = date.toDateString();
    let data = (await getDoc(doc(graphDataCache, key))).data();

    if (!data) {
      data = await getRangeStats({
        organizationId,
        startDate: date,
        endDate: endDateArg,
      });
    }

    result[key] = data as OrganizationStats;
  }

  const totals = Object.values(result).reduce((acc, curr) => {
    Object.keys(curr).forEach((key) => {
      const currKey = key as keyof OrganizationStats;
      if (currKey === "timestamp") {
        return;
      }
      acc[currKey] = (acc[currKey] || 0) + (curr[currKey] ?? 0);
    });
    return acc;
  }, {} as OrganizationStats);

  result.totals = totals;

  return result;
};
