import React, { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  useForm,
  SubmitHandler,
  Control,
  UseFormSetValue,
  FieldErrors,
} from "react-hook-form";
import { styled } from "@mui/system";

import {
  onboardingFormAtom,
  organizationAtom,
  signedInUserAtom,
} from "../../state/atoms";
import {
  convertAppOrganizationSettingsToDbOrganizationSettings,
  convertDbAccountToAppAccount,
  convertDbOrganizationSettingsToAppOrganizationSettings,
  generateCustomAuthToken,
  getDbUser,
  getMyOrganization,
  updateOrganizationSettings,
} from "../../services/firebase";
import { ProfileType } from "../../types/users";
import { AccountSettings } from "../../types/organizations";
import { BouncePrevention, OnboardingSteps } from "../../types/forms";
import { DEFAULT_CUSTOM_FIELD_IDS } from "../../common/consts";
import { removeUndefinedAndNull } from "../../common/utils/data";
import { useConnectOutreach } from "../../common/hooks/useConnectOutreach";
import { makeShouldForwardProps } from "../../common/utils/ui";
import Stepper, { StepProps } from "../../components/Display/Stepper";
import { ButtonVariants } from "../../components/Display/Button";
import FormHelper, { Severity } from "../../components/DataInput/FormHelper";

import OutreachAdminPermissions from "./OutreachAdminPermissions";
import ContactAdmin from "./ContactAdmin";
import ConnectOutreach from "./ConnectOutreach";
import InviteUsers from "./InviteUsers";
import BouncePreventing from "./BouncePreventing";
import Notice from "./Notice";
import EmailStatus from "./EmailStatus";
import VerificationDate from "./VerificationDate";
import EnrichedByBoring from "./EnrichedByBoring";
import WatchVideos from "./WatchVideos";

interface StepperWrapperProps {
  shouldUseDefaultHeight?: boolean;
}

const shouldForwardProp = makeShouldForwardProps<StepperWrapperProps>([
  "shouldUseDefaultHeight",
]);
const StepperWrapper = styled("div", {
  shouldForwardProp,
})<StepperWrapperProps>`
  height: ${({ shouldUseDefaultHeight }) =>
    shouldUseDefaultHeight ? "300px" : "unset"};
  width: 400px;
`;

export type FieldControllerPropsBase = {
  control: Control<AccountSettings, unknown>;
  errors: FieldErrors<AccountSettings>;
  setValue: UseFormSetValue<AccountSettings>;
};

const Onboarding: FC = () => {
  const [onboardingForm, setOnboardingForm] =
    useRecoilState(onboardingFormAtom);
  const [signedInUser, setSignedInUser] = useRecoilState(signedInUserAtom);
  const [organization, setOrganization] = useRecoilState(organizationAtom);

  const [loading, setLoading] = useState(false);
  const [shouldBlockConnectOutreach, setShouldBlockConnectOutreach] =
    useState(false);

  const { isProcessing, url, error, openOutreachAuthUrl } =
    useConnectOutreach();

  const isConnectOutreachLoading =
    shouldBlockConnectOutreach || isProcessing || !url;

  const shouldUseDefaultHeight = ![
    OnboardingSteps.Notice,
    OnboardingSteps.SetupBouncePreventing,
  ].includes(onboardingForm.currentStep);

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    trigger,
    formState: { errors },
  } = useForm<AccountSettings>({
    defaultValues: {
      userSettings: {
        firstName: signedInUser?.firstName || "",
        title: signedInUser?.title || "",
        profile: signedInUser?.profile || ProfileType.DEFAULT,
        phoneNumber: signedInUser?.phoneNumber || "",
      },
      organizationSettings: {
        timestampCustomField:
          organization?.timestampCustomField ||
          DEFAULT_CUSTOM_FIELD_IDS.timestampCustomFieldId,
        emailStatusCustomField:
          organization?.emailStatusCustomField ||
          DEFAULT_CUSTOM_FIELD_IDS.emailStatusCustomFieldId,
        enrichedCustomField:
          organization?.enrichedCustomField ||
          DEFAULT_CUSTOM_FIELD_IDS.enrichedCustomFieldId,
      },
    },
  });

  const emailStatusCustomFieldValue = watch(
    "organizationSettings.emailStatusCustomField"
  );

  const timestampCustomFieldValue = watch(
    "organizationSettings.timestampCustomField"
  );

  const navigateToVideos = () =>
    window.open(`https://boringplugins.com/video`, "_blank");

  const closePopup = async () => {
    const customAuthToken = await generateCustomAuthToken();

    if (window.opener) {
      window.opener.postMessage(
        {
          type: "close",
          token: customAuthToken,
        },
        window.location.origin
      );
      window.close();
    }
  };

  const handleSecondaryBtnClick = (params?: {
    skipStepOnSuccess?: boolean;
  }) => {
    const { skipStepOnSuccess } = params || {};

    if (onboardingForm.currentStep > OnboardingSteps.ConfirmOutreachAdmin) {
      setOnboardingForm((onboardingForm) => ({
        ...onboardingForm,
        currentStep: onboardingForm.currentStep - (skipStepOnSuccess ? 2 : 1),
      }));
    }
  };

  const handlePrimaryBtnClick = async (params?: {
    skipStepOnSuccess?: boolean;
  }) => {
    const { skipStepOnSuccess } = params || {};

    if (onboardingForm.currentStep < OnboardingSteps.WatchVideos) {
      setOnboardingForm((onboardingForm) => ({
        ...onboardingForm,
        currentStep: onboardingForm.currentStep + (skipStepOnSuccess ? 2 : 1),
        isOutreachAdmin:
          onboardingForm.currentStep === OnboardingSteps.ConfirmOutreachAdmin
            ? !!skipStepOnSuccess
            : onboardingForm.isOutreachAdmin,
      }));
    }
  };

  const onSubmit: SubmitHandler<AccountSettings> = async (
    data: AccountSettings
  ) => {
    if (!signedInUser || !organization) return;

    setLoading(true);

    try {
      const updatedSettings: AccountSettings = {
        ...data,
        organizationSettings:
          convertAppOrganizationSettingsToDbOrganizationSettings({
            ...data.organizationSettings,
            allowedOwnerEmails: onboardingForm.allowedOwnerEmails,
            timestampCustomField:
              data.organizationSettings.timestampCustomField,
            emailStatusCustomField:
              data.organizationSettings.emailStatusCustomField,
            enrichedCustomField: data.organizationSettings.enrichedCustomField,
            isEnrichedCustomFieldEnabled: true,
            isTimestampCustomFieldEnabled: true,
            isEmailStatusCustomFieldEnabled: true,
            isFinishedOnboarding: true,
          }),
      };

      await updateOrganizationSettings(
        removeUndefinedAndNull<AccountSettings>(updatedSettings)
      );

      const [organizationResponse, dbUser] = await Promise.all([
        getMyOrganization(),
        getDbUser(signedInUser.uid),
      ]);

      const updatedUser = convertDbAccountToAppAccount(
        dbUser,
        organizationResponse.result
      );

      setOrganization(
        convertDbOrganizationSettingsToAppOrganizationSettings(
          organizationResponse.result || {}
        )
      );
      setSignedInUser(updatedUser);
      setOnboardingForm((onboardingForm) => ({
        ...onboardingForm,
        currentStep: onboardingForm.currentStep + 1,
      }));
    } catch (error) {
      console.error("Failed to update settings:", error?.toString());
    } finally {
      setLoading(false);
    }
  };

  const steps: StepProps[] = [
    {
      component: <OutreachAdminPermissions />,
      secondaryButtonProps: {
        onClick: () =>
          handlePrimaryBtnClick({
            skipStepOnSuccess: true,
          }),
        children: "Yes",
        variant: ButtonVariants.brand,
      },
      primaryButtonProps: {
        onClick: () => handlePrimaryBtnClick(),
        children: "No",
        variant: ButtonVariants.light,
      },
    },
    {
      component: <ContactAdmin />,
      secondaryButtonProps: {
        onClick: () => handleSecondaryBtnClick(),
        children: "I understand",
        variant: ButtonVariants.light,
      },
      backButtonProps: {
        onClick: () => handleSecondaryBtnClick(),
      },
    },
    {
      component: <ConnectOutreach />,
      footer: <FormHelper helperText={error} severity={Severity.error} />,
      primaryButtonProps: {
        onClick: () => {
          setShouldBlockConnectOutreach(true);
          openOutreachAuthUrl();
        },
        type: "button",
        children: "Connect",
        variant: ButtonVariants.brand,
        loading: isConnectOutreachLoading,
        disabled: isConnectOutreachLoading,
      },
      backButtonProps: {
        onClick: () =>
          handleSecondaryBtnClick({
            skipStepOnSuccess: onboardingForm.isOutreachAdmin,
          }),
      },
    },
    {
      component: <BouncePreventing />,
      primaryButtonProps: {
        onClick: () =>
          handlePrimaryBtnClick({
            skipStepOnSuccess:
              onboardingForm.bouncePrevention === BouncePrevention.DoNothing,
          }),
        children: "Next",
        variant: ButtonVariants.brand,
      },
      backButtonProps: {
        onClick: () => handleSecondaryBtnClick(),
      },
    },
    {
      component: <Notice />,
      secondaryButtonProps: {
        onClick: () => handlePrimaryBtnClick(),
        children: "I understand",
        variant: ButtonVariants.light,
      },
      backButtonProps: {
        onClick: () => handleSecondaryBtnClick(),
      },
    },
    {
      component: <InviteUsers />,
      // secondaryButtonProps: {
      //   onClick: () => handlePrimaryBtnClick(),
      //   children: "I'll do this later",
      //   variant: ButtonVariants.light,
      // },
      primaryButtonProps: {
        onClick: () => handlePrimaryBtnClick(),
        children: "Next",
        disabled: loading,
        loading,
        variant: ButtonVariants.brand,
      },
    },
    {
      component: (
        <EmailStatus
          control={control}
          errors={errors}
          timestampCustomFieldValue={timestampCustomFieldValue}
          setValue={setValue}
        />
      ),
      secondaryButtonProps: {
        onClick: () => {
          if (
            emailStatusCustomFieldValue !== organization?.emailStatusCustomField
          ) {
            setValue(
              "organizationSettings.emailStatusCustomField",
              organization?.emailStatusCustomField
            );
          }

          handlePrimaryBtnClick();
        },
        children: "I'll do this later",
        variant: ButtonVariants.light,
      },
      primaryButtonProps: {
        onClick: async () => {
          const isValid = await trigger(
            "organizationSettings.emailStatusCustomField"
          );

          if (isValid) {
            handlePrimaryBtnClick();
          }
        },
        children: "Next",
        disabled: loading,
        loading,
        variant: ButtonVariants.brand,
      },
    },
    {
      component: (
        <VerificationDate
          control={control}
          errors={errors}
          emailStatusCustomFieldValue={emailStatusCustomFieldValue}
          setValue={setValue}
        />
      ),
      secondaryButtonProps: {
        onClick: () => {
          if (
            timestampCustomFieldValue !== organization?.timestampCustomField
          ) {
            setValue(
              "organizationSettings.timestampCustomField",
              organization?.timestampCustomField
            );

            handlePrimaryBtnClick();
          }
        },
        children: "I'll do this later",
        variant: ButtonVariants.light,
      },
      primaryButtonProps: {
        onClick: async () => {
          const isValid = await trigger(
            "organizationSettings.enrichedCustomField"
          );

          if (isValid) {
            handlePrimaryBtnClick();
          }
        },
        children: "Next",
        disabled: loading,
        loading,
      },
      backButtonProps: {
        onClick: () => handleSecondaryBtnClick(),
      },
    },
    {
      component: (
        <EnrichedByBoring
          control={control}
          errors={errors}
          emailStatusCustomFieldValue={emailStatusCustomFieldValue}
          timestampCustomFieldValue={timestampCustomFieldValue}
          setValue={setValue}
        />
      ),
      secondaryButtonProps: {
        onClick: () => {
          handlePrimaryBtnClick();
        },
        children: "I'll do this later",
        variant: ButtonVariants.light,
      },
      primaryButtonProps: {
        onClick: async () => {
          const isValid = await trigger(
            "organizationSettings.enrichedCustomField"
          );

          if (isValid) {
            handleSubmit(onSubmit);
          }
        },
        disabled: loading,
        loading,
        children: "Finish",
        variant: ButtonVariants.brand,
      },
      backButtonProps: {
        onClick: () => handleSecondaryBtnClick(),
      },
    },
    {
      component: <WatchVideos />,
      secondaryButtonProps: {
        onClick: () => closePopup(),
        children: "I've seen it",
        variant: ButtonVariants.light,
      },
      primaryButtonProps: {
        onClick: () => {
          closePopup();
          navigateToVideos();
        },
        children: "Watch the video",
        variant: ButtonVariants.brand,
      },
      backButtonProps: {
        onClick: () => handleSecondaryBtnClick(),
      },
    },
  ];

  useEffect(() => {
    reset({
      userSettings: {
        firstName: signedInUser?.firstName || "",
        title: signedInUser?.title || "",
        profile: signedInUser?.profile || ProfileType.DEFAULT,
        phoneNumber: signedInUser?.phoneNumber || "",
      },
      organizationSettings: {
        timestampCustomField:
          organization?.timestampCustomField ||
          DEFAULT_CUSTOM_FIELD_IDS.timestampCustomFieldId,
        emailStatusCustomField:
          organization?.emailStatusCustomField ||
          DEFAULT_CUSTOM_FIELD_IDS.emailStatusCustomFieldId,
        enrichedCustomField:
          organization?.enrichedCustomField ||
          DEFAULT_CUSTOM_FIELD_IDS.enrichedCustomFieldId,
      },
    });
  }, [signedInUser, organization, reset]);

  useEffect(() => {
    if (
      signedInUser?.isOutreachAuthorized &&
      onboardingForm.currentStep < OnboardingSteps.InviteUsers
    ) {
      setOnboardingForm((onboardingForm) => ({
        ...onboardingForm,
        currentStep: OnboardingSteps.InviteUsers,
      }));
      return;
    }
  }, [
    onboardingForm.currentStep,
    setOnboardingForm,
    signedInUser?.isOutreachAuthorized,
  ]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex align-items-center justify-content-center w-100 h-100"
    >
      <StepperWrapper shouldUseDefaultHeight={shouldUseDefaultHeight}>
        <Stepper
          steps={steps}
          currentStep={onboardingForm.currentStep}
          onCurrentStepChange={(newStep) =>
            setOnboardingForm({ ...onboardingForm, currentStep: newStep })
          }
        />
      </StepperWrapper>
    </form>
  );
};

export default Onboarding;
