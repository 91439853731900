import { Timestamp } from "firebase/firestore";
import { UserSettings } from "./users";

export enum OrganizationStatus {
  TRIAL = "trial",
  PAID = "paid",
  SUSPENDED = "suspended",
}

export enum prospectRunStatus {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export interface DbOrganizationSettings {
  isCustomFieldEnabled?: boolean;
  customField?: number | null;
  disableOptOutProspects?: boolean;
  isTimestampCustomFieldEnabled?: boolean;
  timestampCustomField?: number | null;
  isEnrichedCustomFieldEnabled?: boolean;
  enrichedCustomField?: number | null;
  trialDetails?: DbOrganizationTrialDetails;
  isPayingCustomer?: boolean;
  isSegmentedAccount?: boolean;
  isTrial?: boolean;
  isFinishedOnboarding?: boolean;
  allowedOwnerEmails?: string[];
  allowedOwnerNames?: string[];
}

export interface DbOrganizationTrialDetails {
  startedAt: string;
  expiresAt: string;
  remainingVerifications: number;
}
export interface OrganizationSettings
  extends Omit<DbOrganizationSettings, "isCustomFieldEnabled" | "customField"> {
  isEmailStatusCustomFieldEnabled?: boolean;
  emailStatusCustomField?: number | null;
}

export interface OrgWebhookStatus {
  active?: boolean;
}

export interface Organization extends OrganizationSettings {
  id?: string;
  name?: string;
  status?: OrganizationStatus;
  isOutreachAuthorized?: boolean;
  outreachAuthentication?: OutreachAuthentication;
  prospectsWebhookId?: number;
  isEnrichmentEnabled?: boolean;
}

export interface OutreachAuthentication {
  authorizationCode: string;
  accessToken?: string;
  refreshToken?: string;
  expiresAt?: number; // TODO: deprecated - we should use accessTokenExpiresAt, remove this
  accessTokenExpiresAt?: number;
}

export interface StatProspect {
  prospectId: string;
  email: string;
  emailCheckResult: EmailCheckResult;
}

export interface AccountSettings {
  userSettings: UserSettings;
  organizationSettings: OrganizationSettings;
}

export enum OutreachEmailStatuses {
  Valid = "valid",
  Invalid = "invalid",
  Questionable = "questionable",
  NoEmail = "noEmail",
}

export enum ExtendedEmailStatuses {
  EnrichedByBoring = "enrichedByBoring",
  BouncesPrevented = "bouncesPrevented",
}

export enum GeneralStatuses {
  ProspectsChecked = "prospectsChecked",
}

export type EmailStatuses =
  | OutreachEmailStatuses
  | ExtendedEmailStatuses
  | GeneralStatuses;

export interface OrganizationStats {
  emailsChecked?: number;
  prospectsChecked?: number;
  [ExtendedEmailStatuses.EnrichedByBoring]?: number;
  [ExtendedEmailStatuses.BouncesPrevented]?: number;
  [OutreachEmailStatuses.Valid]?: number;
  [OutreachEmailStatuses.Invalid]?: number;
  [OutreachEmailStatuses.Questionable]?: number;
  [OutreachEmailStatuses.NoEmail]?: number;
  timestamp?: Timestamp;
}

export interface AbstractApiParam {
  text: string;
  value: boolean;
}

export interface AbstractApiResult {
  email: string;
  auto_correct: string;
  deliverability: string;
  quality_score: number;
  is_valid_format: AbstractApiParam;
  is_free_email: AbstractApiParam;
  is_disposable_email: AbstractApiParam;
  is_role_email: AbstractApiParam;
  is_catchall_email: AbstractApiParam;
  is_mx_found: AbstractApiParam;
  is_smtp_valid: AbstractApiParam;
}

export interface EmailCheckResult {
  email: string;
  score: number;
  isLikelyValid?: boolean;
  isSyntaxValid: boolean;
  isBurnerEmail?: boolean;
  isMxFound?: boolean;
  abstractApiResult?: AbstractApiResult;
}
