import { OrganizationSettings } from "./organizations";

export enum BouncePrevention {
  AddSequences = "addSequences",
  BlockSequences = "blockSequences",
  DoNothing = "doNothing",
}

export enum OnboardingSteps {
  ConfirmOutreachAdmin,
  ContactOutreachAdmin,
  ConnectOutreach,
  SetupBouncePreventing,
  Notice,
  InviteUsers,
  ConfigureFieldEmailStatus,
  ConfigureFieldVerificationDate,
  ConfigureFieldEnrichedByBoring,
  WatchVideos,
}

export interface SignupForm {
  name: string;
  organizationName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export interface OnboardingForm
  extends Pick<OrganizationSettings, "allowedOwnerEmails"> {
  currentStep: OnboardingSteps;
  bouncePrevention: BouncePrevention;
  isOutreachAdmin: boolean;
}

export interface LoginForm {
  email: string;
  password: string;
}

export interface ResetPasswordForm {
  email: string;
}

export interface UserDocument {
  email: string;
  authorizationCode: string;
  lastEmailsRun: string;
}
